import React, { useEffect, useState } from "react";
import { Button } from "@paljs/ui/Button";
import { InputGroup } from "@paljs/ui/Input";
import { Link } from "gatsby";
import Layout from "../components/Layouts";
import produce from "immer";
import Seo from "../components/SEO";
import Auth, { Group } from "../components/Auth";
import { useForgotPassword } from "../mutations";
import _ from "lodash";

const RequestPassword = () => {
  const [disable, setDisable] = useState(false);
  const { data, error, setData: setVariable } = useForgotPassword();

  const [formData, setFormData] = useState({
    email: "",
  });

  const onInputChange = ({ target: { type, name, value, checked } }) => {
    setFormData(
      produce(formData, (draft) => {
        draft[name] = type === "checkbox" ? checked : value;
      })
    );
  };
  const onSubmit = (e) => {
    e.preventDefault(); // prevents default submit behavior (i.e. reset page)
    setDisable(true);
    setVariable({
      variables: {
        email: `${formData.email}`,
      },
    });
  };
  useEffect(() => {
    if (_.isEmpty(data)) return;

    setDisable(false);
  }, [data, error]);

  return (
    <Layout>
      <Auth
        title="Forgot Password"
        subTitle="Enter your email address and we’ll send a link to reset your password"
      >
        <Seo title="Forgot Password" />
        {!_.isEmpty(data) && (
          <>
            <h3 className=" mb-1">Reset email sent!</h3>
            <p className=" mb-4">
              We have just sent an email with a password reset link. If you did
              not receive it, please check your spam folder or send again
            </p>
          </>
        )}

        <form onSubmit={onSubmit}>
          <InputGroup fullWidth>
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              onChange={onInputChange}
              value={formData.email}
              required
            />
          </InputGroup>
          <Button
            status="Success"
            type="submit"
            shape="SemiRound"
            fullWidth
            disabled={disable}
          >
            Request Password
          </Button>
        </form>
        <Group>
          <Link to="/login">Back to Log In</Link>
        </Group>
      </Auth>
    </Layout>
  );
};

export default RequestPassword;
