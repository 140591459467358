import React from "react";

import {
  Layout,
  LayoutContent,
  LayoutContainer,
  LayoutColumns,
  LayoutColumn,
} from "@paljs/ui/Layout";
import icons from "@paljs/icons";
import SimpleLayout from "./SimpleLayout";

const LayoutPage = ({ children }) => {
  return (
    <>
      <SimpleLayout />
      <Layout evaIcons={icons} dir="ltr" className="auth-layout">
        <LayoutContainer>
          <LayoutContent>
            <LayoutColumns>
              <LayoutColumn className="main-content">{children}</LayoutColumn>
            </LayoutColumns>
          </LayoutContent>
        </LayoutContainer>
      </Layout>
    </>
  );
};

export default LayoutPage;
